import React, { useEffect } from 'react';
import * as THEOplayer from 'theoplayer';
import Hls from 'hls.js';
import 'theoplayer/ui.css';
import './Player.css';

import {
  setClientToSdk,
  groupSeek,
  stopSync,
  startSynchronize,
  setGroup,
  attachDeltaListener,
  attachPlaybackRateListener,
} from 'services/SyncService';
import TheoSyncDecorator from 'services/SyncService/TheoSyncDecorator';
import HLSPlayerDecorator from 'services/SyncService/HLSPlayerDecorator';
import { useSelector } from 'react-redux';

// const STREAM = 'https://vn-public-krk-dmz.demo.synamedia.co.uk/content/hlsmp4/low-latency-rugby-hls/master.m3u8';
// const STREAM = 'https://demo-app.sceenic.co/football.m3u8';
const STREAM = 'https://dev-stand.ru/media/video.m3u8';

const Player = ({ isLoggedIn, isSyncRequired }) => {
  const { syncToken } = useSelector((state) => state.celebrity);
  const { participants } = useSelector((state) => state.session);

  useEffect(() => {
    if (!syncToken || !isSyncRequired) {
      return;
    }

    async function sync() {
      await setGroup(syncToken, 'Sceenic');
      await startSynchronize();
    }

    sync();

    return () => {
      stopSync();
    };
  }, [syncToken]);

  useEffect(() => {
    attachDeltaListener((delta) => {
      const el = document.getElementById('delta-info');
      if (el) {
        el.innerText = `${delta}ms`;
      }
    });

    attachPlaybackRateListener((rate) => {
      const el = document.getElementById('rate-info');
      if (el) {
        el.innerText = Number(rate).toFixed(2);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const stream = urlParams.get('stream') || STREAM;

    const $video = document.getElementById('player');
    const hls = new Hls({});
    window.player = hls;
    hls.attachMedia($video);

    hls.once(Hls.Events.MEDIA_ATTACHED, function () {
      hls.loadSource(stream);

      hls.once(Hls.Events.LEVEL_LOADED, function () {
        setClientToSdk(new HLSPlayerDecorator(hls));
      });
    });

    $video.volume = 0.05;

    let firstSeek = true;
    $video.addEventListener('seeked', (e) => {
      console.log('seeked');
      if (firstSeek) {
        firstSeek = false;
        return;
      }

      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      console.log('group seek');
      groupSeek();
    });

    return () => {
      hls.destroy();
    };
    // let waiting = null;
    // player.addEventListener('canplay', () => {
    //   clearTimeout(waiting);
    //   console.log('canplay');
    // });
    // player.addEventListener('waiting', () => {
    //   console.log('waiting');
    //   clearTimeout(waiting);
    //   waiting = setTimeout(() => {
    //     console.log('reset');
    //     firstSeek = true;
    //     player.source = source;
    //   }, 15000);
    // });
  }, [isLoggedIn]);

  const showStats = participants.length > 0;

  return (
    <div className="player-container">
      {showStats && (
        <div className="sync-info">
          <div>
            Delta: <span id="delta-info"></span>
          </div>
          <div>
            Rate: <span id="rate-info"></span>
          </div>
        </div>
      )}

      <video className="player" id="player" controls={true} autoPlay playsInline />
    </div>
  );
};
export default Player;
