import Hls from 'hls.js';
import { PlayerDecorator } from '@sscale/syncsdk';

class HLSDecorator extends PlayerDecorator {
  static DEFAULT_PTS_FREQUENCY = 90000;

  #streamOffset = 0;
  #rememberedPosition = 0;
  #usingProgramDateTime = false;

  #cleanup = [];

  load() {
    if (!Hls.isSupported() || !this.#isLive()) {
      return;
    }

    const setOffsetUsingPTS = (initPTS) => {
      const baseTime = initPTS[0]?.baseTime || initPTS;
      const timescale = initPTS[0]?.timescale || HLSDecorator.DEFAULT_PTS_FREQUENCY;

      const streamOffset = (Math.abs(baseTime) / timescale) * 1000;
      this.#streamOffset = Math.round(streamOffset) || 0;
    };
    const initPTS = this.player.streamController.initPTS;
    if (initPTS[0]) {
      setOffsetUsingPTS(initPTS);
    } else {
      const onInitPtsFound = (event, eventData) => {
        if (eventData.initPTS) {
          setOffsetUsingPTS(eventData.initPTS);
        }
      };
      this.player.on(Hls.Events.INIT_PTS_FOUND, onInitPtsFound);
      this.#cleanup.push(() => this.player.off(Hls.Events.INIT_PTS_FOUND, onInitPtsFound));
    }

    const fragPlaying = this.player.streamController.fragPlaying;
    if (fragPlaying?.programDateTime) {
      this.#usingProgramDateTime = true;

      this.#rememberedPosition = fragPlaying.start;
      this.#streamOffset = Math.round(fragPlaying?.programDateTime);
    }

    const onFragChanged = (event, eventData) => {
      if (eventData.frag.programDateTime) {
        this.#usingProgramDateTime = true;

        this.#rememberedPosition = this.player.media.currentTime;
        this.#streamOffset = Math.round(eventData.frag.programDateTime);
      }
    };
    this.player.on(Hls.Events.FRAG_CHANGED, onFragChanged);
    this.#cleanup.push(() => this.player.off(Hls.Events.FRAG_CHANGED, onFragChanged));
  }

  unload() {
    this.#cleanup.forEach((fn) => fn());
    this.#cleanup = [];
  }

  getPrecisionThreshold() {
    // Improve experience with stuttering issue on iOS and Safari
    if (this.#isSafariOriOS()) {
      return 500;
    }

    if (this.#usingProgramDateTime) {
      return 150;
    }

    return 250;
  }

  isStalled() {
    return this.player.media.readyState < this.player.media.HAVE_FUTURE_DATA;
  }

  isSeekable() {
    return !this.#isLive();
  }

  play() {
    try {
      this.player.media.play();
    } catch (e) {
      console.error('[SYNC] Play error:', e);
    }
  }

  pause() {
    try {
      this.player.media.pause();
    } catch (e) {
      console.error('[SYNC] Pause error:', e);
    }
  }

  mute() {
    try {
      this.player.media.muted = true;
    } catch (e) {
      console.log('[SYNC] Mute error:', e);
    }
  }

  unmute() {
    try {
      this.player.media.muted = false;
    } catch (e) {
      console.log('[SYNC] Unmute error:', e);
    }
  }

  getCurrentPosition() {
    try {
      return Math.round(this.#streamOffset + (this.player.media.currentTime - this.#rememberedPosition) * 1000);
    } catch (e) {
      console.error('[SYNC] Get current position error:', e);
    }

    return 0;
  }

  fastSeekToPosition(position) {
    if (position != null) {
      const time = (position - this.#streamOffset + this.#rememberedPosition * 1000) / 1000;

      try {
        const start = this.player.media.seekable.start(0);
        const end = this.player.media.seekable.end(0);
        if (time <= end && time >= start) {
          window.sdkSeek = true;
          this.player.media.currentTime = time;
        }
      } catch (e) {
        console.error('[SYNC] Seek error:', e);
      }
    }
  }

  isPlaying() {
    try {
      return !this.player.media.paused;
    } catch (e) {
      console.error('[SYNC] Is playing error:', e);
    }

    return false;
  }

  changePlaybackRate(rate) {
    try {
      // Improve experience with stuttering issue on iOS and Safari
      if (this.#isSafariOriOS()) {
        if (rate > 1) {
          rate = Math.ceil(rate * 10) / 10 + 0.3;
        }

        if (Math.ceil(rate * 100) / 100 === Math.ceil(this.getPlaybackRate() * 100) / 100) {
          return;
        }
      }

      this.player.media.playbackRate = rate;
    } catch (e) {
      console.error('[SYNC] Set playback rate error:', e);
    }
  }

  getPlaybackRate() {
    try {
      return this.player.media.playbackRate;
    } catch (e) {
      console.error('[SYNC] Get playback rate error:', e);
    }

    return 0;
  }

  setVolume(volume) {
    try {
      this.player.media.volume = volume;
    } catch (e) {
      console.error('[SYNC] Set volume error:', e);
    }
  }

  #isLive() {
    try {
      const hls = this.player;

      if (!hls.levels) {
        return false;
      }

      const level = hls.levels[hls.currentLevel];
      return !!level && level.details.live;
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  #isSafariOriOS() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isiOS =
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    return isSafari || isiOS;
  }
}

export default HLSDecorator;
