import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WT from '@sscale/wtsdk';
import { off, on, publish } from 'services/PubSub';
import { resetStore as resetCelebrityStore, setStreamSettings } from 'store/reducers/CelebrityReducer';

import { setSessionError, setParticipants, setJoinModalType } from 'store/reducers/SessionReducer';
import celebrityAPI from 'services/celebrity';
import Video from '../Video/Video';
import JoinModal from './components/JoinModal/JoinModal';
import Rooms from './components/Rooms/Rooms';
import Player from '../Player/Player';
import SessionError from './components/SessionError/SessionError';
import Participants from './components/Participants/Participants';
import { Logger, Publisher } from '@sscale/cdnwebsdk';
import { getPublisherToken } from '../../services/cdn';
import Chat from '../Chat/Chat';
import InteractivityLayer from '../InteractivityLayer/InteractivityLayer';
import PollManager from '../Polls/PollManager';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import './Celebrity-ordered.scss';
import PChat from '../../services/PChat';
import { getEVEToken } from '../../services/SyncService';

const publisherLogger = Logger.get('Publisher');
publisherLogger.setLevel(Logger.DEBUG);

let audioContext;
let audioDestination;
const audioSources = {};
const mixedStream = new MediaStream();

const urlParams = new URLSearchParams(window.location.search);
const roomId = urlParams.get('streamRoom') || 'wroom';

function initAudioContextIfNeeded() {
  if (!audioDestination || !audioContext) {
    audioContext = new AudioContext();
    audioDestination = audioContext.createMediaStreamDestination();
  }
}
function prepareMixedStream({ videoTracks, audioTracks }) {
  if (videoTracks) {
    mixedStream.getVideoTracks().forEach((track) => {
      mixedStream.removeTrack(track);
    });
    videoTracks.forEach((track) => {
      mixedStream.addTrack(track);
    });
  }

  if (audioTracks) {
    mixedStream.getAudioTracks().forEach((track) => {
      mixedStream.removeTrack(track);
    });
    audioTracks.forEach((track) => {
      mixedStream.addTrack(track);
    });
  }
}

function drawStar(ctx, x, y, radius, points, innerRadius) {
  ctx.save(); // Save the current state
  ctx.translate(x, y); // Move the origin to the star's center
  ctx.rotate(Math.PI / 5); // Rotate by 18 degrees (π/10 radians)
  ctx.translate(-x, -y); // Move the origin back

  ctx.beginPath();
  ctx.moveTo(x, y + radius);
  for (let i = 0; i < 2 * points; i++) {
    const angle = (i * Math.PI) / points;
    const r = i % 2 === 0 ? radius : innerRadius;
    ctx.lineTo(x + r * Math.sin(angle), y + r * Math.cos(angle));
  }
  ctx.closePath();
  ctx.fillStyle = '#B8860B'; // Darker gold color
  ctx.fill();

  ctx.restore(); // Restore the previous state
}

function getAudioStream(facesOnly) {
  const videoElementsNodeList = document.querySelectorAll('video[id^="participant-"]');
  const videoElements = Array.from(videoElementsNodeList);
  const player = document.getElementById('player');

  if (player && !facesOnly) {
    videoElements.push(player);
  }

  videoElements.forEach((video) => {
    const stream = video.srcObject;
    if (!audioSources[video.id]) {
      initAudioContextIfNeeded();
      const audioSource =
        stream instanceof MediaStream
          ? audioContext.createMediaStreamSource(stream)
          : audioContext.createMediaElementSource(video);
      audioSource.connect(audioDestination);
      audioSources[video.id] = audioSource;
    }
  });

  return audioDestination.stream;
}

function prepareCanvas(ctx, canvas) {
  ctx.fillStyle = '#000000'; // Change this to the color you want
  ctx.beginPath();
  ctx.roundRect(0, 0, canvas.width, canvas.height, 8);
  ctx.fill();
}

function SessionV2({ facesOnly = false }) {
  const dispatch = useDispatch();

  const { isCelebrity, userName, constraints, eveKey } = useSelector((state) => state.celebrity);
  const { participants, connectedClb } = useSelector((state) => state.session);

  const [roomHasCelebrity, setRoomCelebrityPresence] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [publisher, setPublisher] = useState(null);
  const [isCdnMuted, setIsCdnMuted] = useState(false);

  const participantsRef = useRef(participants);
  const connectedClbRef = useRef(connectedClb);
  const makeCelebrityLocalRef = useRef();
  const roomHasCelebrityRef = useRef(roomHasCelebrity);

  useEffect(() => {
    if (isCelebrity !== null) {
      if (roomHasCelebrity) {
        return;
      }

      makeCelebrityLocalRef.current();
    }
  }, [roomHasCelebrity, isCelebrity]);

  useEffect(() => {
    const initChat = async () => {
      if (roomId && userName) {
        await PChat.init(roomId, userName);
        initAudioContextIfNeeded();
      }
    };

    initChat();
  }, [userName]);

  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    roomHasCelebrityRef.current = roomHasCelebrity;
  }, [roomHasCelebrity]);

  useEffect(() => {
    connectedClbRef.current = connectedClb;
  }, [connectedClb]);

  useEffect(() => {
    WT.ErrorsListeners.onSessionError((error) => {
      dispatch(setSessionError('Unexpected error. Unable to join the room.'));
      makeCelebrityLocalRef.current();
    });

    WT.SessionListeners.onConnected(() => {
      connectedClbRef.current();

      let participants = [];

      WT.SessionListeners.onStreamCreated((params) => {
        const alreadyExists = participants.find((p) => p.participantId === params.participantId);

        if (alreadyExists) {
          const video = document.getElementById('participant-' + params.participantId);
          video.src = params.stream;
          // dispatch(setParticipants([...participants.filter(p => p.participantId !== params.participantId), { ...params }]))
        } else if (params.isCelebrity) {
          setRoomCelebrityPresence(params);
        } else {
          participants.push(params);
          dispatch(setParticipants([...participants]));
        }

        initAudioContextIfNeeded();
        const audioSource = audioContext.createMediaStreamSource(params.stream);
        audioSource.connect(audioDestination);
        if (publisher) {
          console.log(`replacing audio  track`);
          publisher.replaceTrack(audioDestination.stream.getAudioTracks()[0]);
        }
      });

      WT.ParticipantListeners.onParticipantLeft(({ participantId }) => {
        if (roomHasCelebrityRef?.current && roomHasCelebrityRef?.current?.participantId === participantId) {
          setRoomCelebrityPresence(null);
        } else {
          participants = [...participants.filter((p) => p.participantId !== participantId)];
          dispatch(setParticipants(participants.filter((p) => p.participantId !== participantId)));
        }
      });
    });

    WT.ParticipantListeners.onParticipantMediaStreamChanged(publish.bind(null, 'onParticipantMediaStreamChanged'));
    WT.ParticipantListeners.onParticipantSpeaking(publish.bind(null, 'onParticipantSpeaking'));
    WT.ParticipantListeners.onParticipantStopSpeaking(publish.bind(null, 'onParticipantStopSpeaking'));

    function onParticipantMediaStreamChanged({ participantId, mediaType, mediaState }) {
      if (publisher) {
        switch (mediaType) {
          case 'VIDEO':
            const videoTrack = participantsRef.current
              .find((p) => p.participantId === participantId)
              ?.stream?.getVideoTracks()[0];
            if (videoTrack) {
              publisher.replaceTrack(videoTrack);
            }
            break;
          case 'AUDIO':
            const audioTrack = participantsRef.current
              .find((p) => p.participantId === participantId)
              ?.stream?.getAudioTracks()[0];
            if (audioTrack) {
              publisher.replaceTrack(audioTrack);
            }
            break;
        }
      }
    }
    on('onParticipantMediaStreamChanged', onParticipantMediaStreamChanged);

    return () => {
      off('onParticipantMediaStreamChanged', onParticipantMediaStreamChanged);
    };
  }, []);

  makeCelebrityLocalRef.current = useCallback(() => {
    dispatch(setParticipants([]));
    navigator.mediaDevices.getUserMedia(constraints).then((device) =>
      setRoomCelebrityPresence({
        stream: device,
        participantId: '1',
        participantName: userName,
        streamConstraints: constraints,
        local: true,
        preview: true,
      }),
    );
  }, [userName, constraints]);

  const stopLocalStream = () => {
    const tracks = roomHasCelebrityRef?.current?.stream?.getTracks();

    if (tracks && tracks.length > 0) {
      tracks[0].stop();
    }
  };

  useEffect(() => {
    return () => {
      stopLocalStream();
    };
  }, []);

  useEffect(() => {
    dispatch(setJoinModalType('CELEBRITY_JOIN_DATA'));
  }, []);

  useEffect(() => {
    let CDN_PREVIEW_INTERVAL_ID = null;
    const canvas = document.getElementById('cdn-preview');
    const padding = facesOnly ? 0 : 24;
    const videoWidth = facesOnly ? 320 : 200;
    const videoHeight = facesOnly ? 240 : 150;

    const videos = document.querySelectorAll('video[id^="participant-"]');
    if (facesOnly) {
      canvas.width = videoWidth;
      canvas.height = videoHeight * videos.length + 3 * padding;
    }
    const ctx = canvas.getContext('2d');

    // Fill the canvas
    prepareCanvas(ctx, canvas);

    const player = document.getElementById('player');

    let videosNumber = videos.length;

    const drawAvailableVideos = () => {
      const videos = document.querySelectorAll('video[id^="participant-"]');
      if (videos.length !== videosNumber && facesOnly) {
        canvas.height = videoHeight * videos.length;
        prepareCanvas(ctx, canvas);
      }

      if (!facesOnly) {
        ctx.drawImage(player, 0, 0, canvas.width, canvas.height);
      }

      videos.forEach((video, index) => {
        const x = facesOnly ? 0 : padding;
        let y = facesOnly ? index * (videoHeight + 5) : 2 * padding + 10 + index * (videoHeight + 5);
        ctx.drawImage(video, x, y, videoWidth, videoHeight);
        if (index === 0 && facesOnly) {
          ctx.strokeStyle = '#FFD700'; // Gold color
          ctx.lineWidth = 5; // Adjust as needed
          ctx.strokeRect(0, 0, videoWidth, videoHeight);
          drawStar(ctx, canvas.width - 30, 30, 15, 5, 7.5);
        }
      });
      // player.requestVideoFrameCallback(drawAvailableVideos); // stopped working when video is paused
      // requestAnimationFrame(drawAvailableVideos); // stopped working when tab is inactive
    };

    // player.requestVideoFrameCallback(drawAvailableVideos);
    // requestAnimationFrame(drawAvailableVideos);

    // create a loop with 60 fps
    const interval = 1000 / 30;
    CDN_PREVIEW_INTERVAL_ID = setInterval(drawAvailableVideos, interval);
  }, []);

  function logout() {
    WT.Session.disconnect();

    stopLocalStream();

    celebrityAPI.logout();

    setRoomCelebrityPresence(null);
    // setRoomsList([]);
    dispatch(setParticipants([]));
    dispatch(resetCelebrityStore());

    dispatch(setJoinModalType('CELEBRITY_JOIN_DATA'));
  }

  function handleMute() {
    console.log('handleMute', isCdnMuted, publisher);
    if (!isCdnMuted) {
      console.log('mute');
      publisher.muteAudio();
      setIsCdnMuted(true);
    } else {
      console.log('unmute');
      publisher.unmuteAudio();
      setIsCdnMuted(false);
    }
  }

  async function startCdnPublishing() {
    if (isPublishing) {
      publisher.unpublish();
      dispatch(setStreamSettings({ syncToken: '' }));
      setIsPublishing(false);
      // window.location.reload();
      return;
    }
    try {
      const token = await getPublisherToken(roomId);
      const canvas = document.getElementById('cdn-preview');
      const stream = canvas.captureStream();
      const audioStream = getAudioStream(facesOnly);

      const videoTracks = stream.getVideoTracks();
      const audioTracks = audioStream.getAudioTracks();
      prepareMixedStream({ videoTracks, audioTracks });

      const url = 'https://cdn.management.sceenic.co/v1/stream';
      if (!publisher) {
        const publisher = new Publisher(url, token);
        publisher.publish(mixedStream, 6000000, true);
        window.startRTMP = (url, bitrate) => {
          publisher.exportToRtmp(url, bitrate);
        };
        // collecting stats on publisher side causing some stuttering in canvas loop
        // publisher.startCollectingStats();
        // setTimeout(() => {
        //   publisher.stopCollectingStats();
        // }, 10000);
        setPublisher(publisher);
      }

      const eveSyncToken = await getEVEToken(roomId, userName, eveKey);
      dispatch(setStreamSettings({ syncToken: eveSyncToken }));

      setIsPublishing(true);
    } catch (error) {
      console.error('Error getting publisher token', error);
    }
  }

  return (
    <div className="session-container is-celebrity-page">
      <div className="s-body">
        <div className="s-celebrity-container">
          <div className="s-celebrity-video">
            <Video participant={roomHasCelebrity} isControlled={true} isCelebrity={true} />
          </div>
          <button className="s-logout" onClick={logout} title={'Log out'} />
          <div className="s-celebrity-controls">
            <canvas className="canvas-cdn-preview" id="cdn-preview" width="1280" height="720" />
            <button className="button room-next-button" onClick={startCdnPublishing}>
              {isPublishing ? 'Stop' : 'Start'} Streaming
            </button>
            {/*<button className="button" onClick={handleMute}>*/}
            {/*  Mute*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="s-activity-container">
          <div className="s-participants">
            <Participants />
          </div>

          <div className="s-player-container">
            <Player isLoggedIn={isCelebrity} isSyncRequired={true} />
            {userName && <InteractivityLayer roomId={roomId} userId={userName} />}
          </div>
        </div>

        <div className="tools">
          <Tabs>
            <TabList>
              <Tab>Queue Manager</Tab>
              <Tab>Chat</Tab>
              <Tab>Polls</Tab>
            </TabList>

            <TabPanel>
              <Rooms key={userName} isLoggedIn={isCelebrity} makeCelebrityLocal={makeCelebrityLocalRef.current} />
            </TabPanel>
            <TabPanel
            // forceRender={true}
            >
              <Chat userId={userName} roomId={roomId} />
            </TabPanel>
            <TabPanel>
              <PollManager />
            </TabPanel>
          </Tabs>
          {/*<Rooms key={userName} isLoggedIn={isCelebrity} makeCelebrityLocal={makeCelebrityLocalRef.current} />*/}
          {/*<PollManager />*/}
        </div>

        <JoinModal />

        <SessionError />
      </div>
    </div>
  );
}

export default SessionV2;
